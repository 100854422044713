<template>
  <v-card
    :class="{
      'card--flex-toolbar-xs': $vuetify.breakpoint.xsOnly,
      'card--flex-toolbar': $vuetify.breakpoint.smAndUp
    }"
  >
    <v-toolbar flat dense class="elevation-3" color="secondary">
      <v-toolbar-title class="body-2 white--text">
        {{ this.$route.meta.subtitle }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text :class="{ 'pa-0': $vuetify.breakpoint.xsOnly }">
      <v-container
        fluid
        class="pt-0"
        :class="{ 'pa-0': $vuetify.breakpoint.xsOnly }"
      >
        <v-row>
          <v-col cols="12" md="6" lg="3" xl="2">
            <v-card elevation="4">
              <v-card-title class="bg1">Članovi odbora</v-card-title>
              <v-card-subtitle class="mt-2"
                >Popis članova odbora, funkcija i statusa plaćanja
                članarina</v-card-subtitle
              >
              <v-card-text>
                <v-select
                  v-model="selboard"
                  return-object
                  label="Odbor"
                  :items="boards"
                  item-text="name"
                  item-value="id"
                ></v-select>
                <v-select
                  v-model="selyear"
                  label="Godina"
                  :items="years"
                ></v-select>
              </v-card-text>
              <v-card-actions>
                <v-btn
                  @click="
                    downloadReport(
                      'boardmembers',
                      'pdf',
                      selboard.id,
                      selboard.name,
                      selyear
                    )
                  "
                  text
                  :loading="loadingBoardMembers"
                >
                  <v-icon color="error" class="mr-1">picture_as_pdf</v-icon
                  >Preuzmi PDF
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>
<script>
import moment from "moment";

export default {
  data() {
    return {
      boards: [],
      selboard: null,
      loadingBoardMembers: false,
      selyear: moment().year(),
      years: []
    };
  },
  methods: {
    downloadReport(report, f, p1, p2, p3) {
      switch (report) {
        case "boardmembers":
          this.loadingBoardMembers = true;
          break;
      }
      this.$http
        .get("report/report.php", {
          params: {
            name: report,
            format: f,
            param1: p1,
            param2: p2,
            param3: p3
          },
          responseType: "blob"
        })
        .then(response => {
          switch (report) {
            case "boardmembers":
              this.loadingBoardMembers = false;
              break;
          }
          const fileName = response.headers["x-suggested-filename"];
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
        });
    }
  },
  mounted() {
    var me = this;
    this.$http
      .get("/board/boards.php", {
        params: {
          sort_column: ["name"],
          sort_directon: "ASC",
          page_size: 1000
        }
      })
      .then(response => {
        me.boards = response.data.rows;
      });
    var cyear = moment().year();
    var y = cyear;
    while(y>=2020) {
      this.years.push(y);
      y--;
    }
  }
};
</script>

<style scoped>
.bg1 {
  background-color: #00bcd4;
  color: white;
}
</style>
