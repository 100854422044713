var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{class:{
    'card--flex-toolbar-xs': _vm.$vuetify.breakpoint.xsOnly,
    'card--flex-toolbar': _vm.$vuetify.breakpoint.smAndUp
  }},[_c('v-toolbar',{staticClass:"elevation-3",attrs:{"flat":"","dense":"","color":"secondary"}},[_c('v-toolbar-title',{staticClass:"body-2 white--text"},[_vm._v(" "+_vm._s(this.$route.meta.subtitle)+" ")]),_c('v-spacer')],1),_c('v-card-text',{class:{ 'pa-0': _vm.$vuetify.breakpoint.xsOnly }},[_c('v-container',{staticClass:"pt-0",class:{ 'pa-0': _vm.$vuetify.breakpoint.xsOnly },attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"3","xl":"2"}},[_c('v-card',{attrs:{"elevation":"4"}},[_c('v-card-title',{staticClass:"bg1"},[_vm._v("Članovi odbora")]),_c('v-card-subtitle',{staticClass:"mt-2"},[_vm._v("Popis članova odbora, funkcija i statusa plaćanja članarina")]),_c('v-card-text',[_c('v-select',{attrs:{"return-object":"","label":"Odbor","items":_vm.boards,"item-text":"name","item-value":"id"},model:{value:(_vm.selboard),callback:function ($$v) {_vm.selboard=$$v},expression:"selboard"}}),_c('v-select',{attrs:{"label":"Godina","items":_vm.years},model:{value:(_vm.selyear),callback:function ($$v) {_vm.selyear=$$v},expression:"selyear"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","loading":_vm.loadingBoardMembers},on:{"click":function($event){return _vm.downloadReport(
                    'boardmembers',
                    'pdf',
                    _vm.selboard.id,
                    _vm.selboard.name,
                    _vm.selyear
                  )}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"error"}},[_vm._v("picture_as_pdf")]),_vm._v("Preuzmi PDF ")],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }